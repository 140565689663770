.link{
    color: #ffffff;
    list-style: none;
    text-decoration: none;
    text-align: right;
    transition: 0.3s ease-in-out;
    &:hover{
        color: rgb(62, 5, 255);
    }

}

.text-white{
    color: #ffffff;
}

.text-section{
    padding: 30px;
}

footer{
    padding: 50px 25px;
    font-size: 1.1em;
}

.footer-logo{
    padding-bottom: 75px;
    position: relative;
}

.asterisk-footer{
    position: absolute;
    top: 0;
    left: 35%;
    
}

.image-footer{
    max-width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}


@media screen and (max-width: 450px) {
    footer{
        padding: 50px 25px;
        font-size: 0.7em;
    }
}