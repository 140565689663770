.star{
    fill: rgb(62, 5, 255);
    font-size: 20px;
}

.review-container{
    padding: 5%;
}

.skills{
    height: 500px;
}

.text-about{
    font-size: 1.3em;
    width: 100%;
}

.skill{
    font-size: 8em;
    fill: rgb(62, 5, 255);
    padding: 20px;
    transform: rotate(-40deg);
    border-radius: 50%;
    background-color: #ffffff;

}

.about-padding{
    padding-top: 100px;
}

.title-team{
    padding: 3.5rem 0;
    font-weight: 700;
}

.container-team{
    padding-bottom: 7rem;
}


.hover-underline-animation {
    display: inline-block;
    position: relative;
   
  }
  
  .hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgb(62, 5, 255);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }


  .team-list{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px ,1fr));
    gap: 2rem
  }


  .team-img-container {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    width: 180px;
    height: 180px;
}

.team-img-container img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.5s ease;
}

.team-overlay-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 255, 0.3); /* Blue with low opacity */
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1;
}

.team-img-container:hover .team-overlay-color {
    opacity: 1;
}

.team-img-container:hover img {
    transform: scale(1.2);
    z-index: 2;
}

  

  @media screen and (max-width: 768px) {
    .text-about{
        font-size: 1em;
       
    }
    .skills{
        height: 300px;
    }

    .review-container{
        padding: 10%;
    }
    .review-titles-container{

        h4{
            font-size: 1em
        }
        h3{
            font-size: 1em;
        }
      }
  }