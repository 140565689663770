
.navbar {
  
  background-color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: start;
  width: 100%;
  
}

.logo-img{
  max-width: 100%;
  
  display: block;
  object-fit: cover;
}



.otherNavbar{
  justify-content: space-between; 
  align-items: center;
  flex-direction: row;
  display: flex;
  padding: 20px;
  background-color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 1000;
}

.logo-container {
  display: flex;
  align-items: center;
  transform-origin: 0 -30%;
  
 /* Add transitions for the transformations */
}

.img-seq{
  transform-origin: 0 0;
}

.sequence-logo{
  position: relative;
  gap: 1%;
  user-select: none;
}

.asterisk{
  position: absolute;
  top: -44%;
  left: 34%;
  width: 5%;
}

.uence{
 z-index: -1;
  display: inline-block;
  width: 60%;
 
}
.seq{
  
  display: inline-block;
  width: 35%;
  
}

.logo-letter{
  display: block;
  max-width: 100%;
  object-fit: cover;
}

.logo {
  max-width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}


.links-social {
  display: flex;
  justify-content: end;
  align-items: start;
  width: 100%;
  gap: 20px;
  font-family: 'Gothic';
  font-size: 19px;
 

}

.selected{
  color: rgb(62, 5, 255);
  transition: 0.3s;
}


.link-social{
 transition:  fill 0.3s ease-in-out;
  &:hover{
    fill: #3e05ff;
  }
}





@media (max-width: 1350px){
  .logo-container {
    display: flex;
    align-items: center;
    transform-origin: 0 -38%;
    
  
  }
}


@media (max-height: 750px){
  .logo-container {
    display: flex;
    align-items: center;
    transform-origin: 0 -38%;
    
  
  }
}
