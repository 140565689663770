.text-header{
    font-size: 1.5rem;
    color: #ffffff;
    text-align: left;
    padding: 100px 20px;
    text-align: start;
  
}

.hi{
    display: inline-flex;
    color: #3e05ff;
    cursor: pointer;

   
}

.text-desc{
  
}

.video-container{ 
    height: 900px;
}

.video{
   width: 100%;
   height: 100%;
   object-fit: cover;
}



@media screen and (max-width: 1200px) {
    .text-header{
        font-size: 1.3rem ;
        padding-top:100px;
    }

    .video-container{ 
        height: auto;
    }
    
    .video{
       width: 100%;
       height: 100%;
       object-fit: cover;
    }
    
}


@media screen and (max-width: 450px) {
    .text-header{
        font-size: 1rem;
    }
 
}