.App {
  text-align: center;
  background: #000;
 
}

body::-webkit-scrollbar{
  width: 0.5vw;
}
body::-webkit-scrollbar-track{
  background-color: #000;
}

body::-webkit-scrollbar-thumb{
  background-color: #3e05ff;
  border-radius: 30px;
}



.black{
  background: #000;
}

.bg-blue{
  background-color: rgb(62, 5, 255);
}

.color-blue{
  color: #3e05ff;
}


.text-bolder{
  font-weight: 900;
}

button{
  background-color: rgb(62, 5, 255);
  border: none;
  color: rgb(255, 255, 255);
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
  font-weight: 700;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.slide-in{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(62, 5, 255);;
  transform-origin: bottom;
  z-index: 1000;
}
.slide-out{
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(62, 5, 255);
  transform-origin: top;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
