/* Porjects.scss */

  .titles{
    padding-bottom: 20px;
  }
    .title-projects{
      color: #ffffff;
      font-size: 1.3rem;
      transition: 0.3s;
      cursor: pointer;
      &:hover{
        color: rgb(62, 5, 255);
      }
    }

    .selected{
      color: rgb(62, 5, 255);
      transition: 0.3s;
    }

//////////////////////////////////////////////////////////////
///Grid Container/////////////////////////////////////////////
    .layout {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 20px;
      padding: 20px;
    }


    .item:nth-child(7n + 1) {
      grid-column: 3 span; 
    }
    .item:nth-child(7n + 2) {
      grid-column: 5 span; 
    }
    .item:nth-child(7n + 3) {
      grid-column: 4 span; 
    }
    .item:nth-child(7n + 4) {
      grid-column: 7 span; 
    }
    .item:nth-child(7n + 5) {
      grid-column: 5 span; 
    }
    .item:nth-child(7n + 6) {
      grid-column: 6 span; 
    }
    .item:nth-child(7n + 7) {
      grid-column: 6 span; 
    }


    @media screen and (max-width: 768px) {
      .title-projects{
        font-size:1rem;
      }
      .item:nth-child(7n + 1) {
        grid-column: span 12; 
      }
      .item:nth-child(7n + 2) {
        grid-column: span 12; 
      }
      .item:nth-child(7n + 3) {
        grid-column: span 12; 
      }
      .item:nth-child(7n + 4) {
        grid-column: span 12 
      }
      .item:nth-child(7n + 5) {
        grid-column:span 12;
      }
      .item:nth-child(7n + 6) {
        grid-column: span 12;
      }
      .item:nth-child(7n + 7) {
        grid-column: span 12; 
      }
    }


    ///////////////////////////////////////////
    /// image

    .item {
      position: relative;
      cursor: pointer;
      max-height: 450px; 
      overflow: hidden;
      
    }


    
   
    
    
    .image-container {
      background-color: aliceblue;
      width: 100%;
     height: 100%;
      
    }
    
    .image-project {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease; /* Include transform transition */
    }
    
    .color-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(62, 5, 255, 0); /* Initial transparent color */
      transition: background-color 0.3s ease; /* Include background-color transition */
    }
    
    .project-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: transparent;
      transition: color 0.3s ease;
      text-align: center;
      pointer-events: none;
      font-size: 1.1rem;
      font-weight: 700;
    }
    
    .item:hover .project-title {
      color: white; /* Show project title on hover */
      cursor: pointer;
    }
    
    .item:hover .color-overlay {
      background-color: rgba(62, 5, 255, 0.8); /* Change color on hover */
    }
    
    .item:hover .image-project {
      transform: scale(1.1); /* Scale up the image on hover */
    }