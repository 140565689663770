:root {
    --mainColor: #000000;
    --white: #ffffff;
    --secondaryColor: #3e05ff;
    --textColor: #000000;
}

.link-navbar{
    text-decoration: none;
    font-size: 1.2rem;  
    color: #fff;
    font-weight: 600;
}

.navbar-bgc{
    background-color:#0f0627 ;
    padding: 20px 0;
}


nav .logo-div {
    display: none;
}

.hidden{
    display: none;
}

header {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 2rem;
    background-color: var(--textColor);
    
}

nav a {
    
    color: var(--textColor);
    text-decoration: none;
    transition: 0.3s ease-in-out;
}

nav a:hover {
    color: var(--secondaryColor);
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--white);
    display: none;
    opacity: 0;
    font-size: 1.8rem;
}

header div,
nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.res-logo-menu{
    width: 100px;
    position: absolute;
    top: 2rem;
    left: 2rem;
    display: none;
}
.nav-hamburger{
    display: none;
}

@media only screen and (max-width: 1024px) {
    .res-logo-menu{
        display: block;
    }

    .nav-hamburger{
        position: relative;
        z-index: 889;
        width: 80px;
        height: 60px;
        top: 0.2rem;
        border-radius: 50%;
        display: block;
    }

    .line{
        width: 25px;
        height: 2px;
        background-color: #fff;
        position: absolute;
    }


    header{
        justify-content: space-between;
       
    }
    header .nav-btn {
        position: relative;
        z-index: 889;
        display: block;
        opacity: 1;
    }


    header nav {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 1s;
        z-index: 888;
    }

    header .responsive_nav {
        transform: translateY(100vh);
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav .logo-div{
        position: absolute;
            top: 2rem;
            left: 2rem;
            display: block;
    }
}