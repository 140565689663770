.media-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  padding: 15px 0;
}

@media (max-width: 768px) {
  .media-grid {
    grid-template-columns: 1fr;
    padding: 20px;
  }
}

.media-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.blur-placeholder {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.media-wrapper {
  position: relative;
}

.media-item.loaded {
  display: block;
}


.arrows{
  font-size: 5rem;
  cursor: pointer;
}


.details-container{
   
    padding-top: 100px;
   
}

.similar-item{
     position: relative;
      cursor: pointer;
      overflow: hidden;
      
}

.title-details{
    font-size: 2em;
    font-weight: 700;
}

.credits-details{
    font-size: 1.1em;
    white-space: pre-wrap; /* Preserve white spaces and allow wrapping */
  overflow-x: auto; /* Enable horizontal scrolling if content overflows */
  max-width: 100%; 
}

.player-wrapper {
  max-width: 1000px;
}

.vimeo-video{
  position: absolute;
  top: 0;
  left: 0;
}

.similar-projects-container{
  padding: 15% 0 ;

  h3{
    font-size: 2em;
    font-weight: 700;
  }
  
}



.blur-placeholder {
  width: 100%;
  height: 100%;
  filter: blur(20px);
}

.image-wrapper {
  position: relative;
  overflow: hidden;
}

.images-grid img {
  transition: opacity 0.5s ease-in-out;
}

/* .images-grid img.loaded {
  opacity: 1;
}

.images-grid img:not(.loaded) {
  opacity: 0;
} */



  @media screen and (max-width: 768px) {
    .arrows{
      font-size: 2rem;
    }
    .credits-details{
      font-size: 1em;
  }
  
  }


  @media screen and (max-width: 450px) {
   

  .images-grid{
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding: 20px;
  }
  }