.contact{
    background-color: #fff;
    padding-top: 150px;
    color: #000000;
}


.contact-text{
    font-size: 1.4em;
    font-weight: 600;
    text-align: left;
    overflow: hidden;
}


.carousel{
    padding: 30px 0;
}

.contact-input{
    border: none;
    outline: none;

}

.error-message{
    font-size: 15px;
   
 
}

.input-div{
    border-bottom: #000000 solid 1px;
}

.input-div label{
    font-weight: 600;
  
}

.send-btn{
    width: 60%;
    font-weight: 100;
    align-self: self-start;
    background-color: transparent;
}

.form-container{
    padding: 50px 0 150px 0;
}

.text-contact-container{
    width: 50%;
}


@media screen and (max-width: 500px) {
    .contact-text{
        font-size: 1em;
        
    }
    .input-div label{
        font-size: 0.8em;
    }
    .error-message{
        font-size: 0.7em;
    }
    .text-contact-container{
        width: 100%;
    }
}

@media screen and (min-width: 500px ) and (max-width: 1000px) {
    .text-contact-container{
        width: 75%;
    }
}